
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Loading from "vue-loading-overlay";

interface editArea {
  name: string;
  picArea: string;
  note: string;
  email: any;
  phone: string;
  address: {
    province: string;
    city: string;
    regency: string;
    detail: string;
  };
}

interface items {
  provinsiOptions: any;
  kabupatenOptions: any;
  kecamatanOptions: any;
}

export default defineComponent({
  name: "Area",
  components: {
    ErrorMessage,
    Field,
    Loading,
    Form,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    let items = reactive<items>({
      provinsiOptions: [],
      kabupatenOptions: [],
      kecamatanOptions: [],
    });

    const editAreaValidator = Yup.object().shape({
      name: Yup.string().required("Nama Area Wajib Diisi"),
      picArea: Yup.string().required("Penanggung Jawab Wajib Diisi"),
      phone: Yup.string().required("Nomor Telepon Wajib Diisi"),
      email: Yup.string().required("Email Wajib Diisi").email(),
      province: Yup.string().required("Provinsi Wajib Diisi"),
      city: Yup.string().required("Kabupaten/ Kota Wajib Diisi"),
      regency: Yup.string().required("Kecamatan Wajib Diisi"),
    });

    const editArea = reactive<editArea>({
      name: "",
      picArea: "",
      note: "",
      email: "",
      phone: "",
      address: {
        province: "",
        city: "",
        regency: "",
        detail: "",
      },
    });

    const saveChanges1 = () => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      isLoading.value = true;

      // dummy delay
      setTimeout(() => {
        // Send login request

        ApiService.putWithData(
          "crmv2/main_bank/bank/office/area/" +
            route.params.idBank +
            "/" +
            route.params.idArea,
          editArea
        )
          .then((res) => {
            toast.success("Berhasil Ubah Area");
            isLoading.value = false;

            router.push({ path: "/superadmin/area" });
          })
          .catch((e) => {
            toast.error(e.response.data.detail);
            isLoading.value = false;
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    const getIdArea = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_bank/bank/office/area/" +
          route.params.idBank +
          "/" +
          route.params.idArea
      )
        .then((res) => {
          editArea.name = res.data.area[0].name;
          editArea.picArea = res.data.area[0].picArea;
          editArea.note = res.data.area[0].note;
          editArea.email = res.data.area[0].email;
          editArea.phone = res.data.area[0].phone;
          editArea.address.province = res.data.area[0].address.province;
          editArea.address.city = res.data.area[0].address.city;
          editArea.address.regency = res.data.area[0].address.regency;
          editArea.address.detail = res.data.area[0].address.detail;

          getTempProv();
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
        });
    };

    // untuk mencari id prov dari nama prov yang ada
    const getTempProv = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        items.provinsiOptions = response.data;

        let indexProv = items.provinsiOptions.findIndex(
          (x) => x.name === editArea.address.province
        );

        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getAllProvinces"
        ).then((response) => {
          localStorage.setItem(
            "idProvinsi",
            JSON.stringify(response.data[indexProv].id)
          );

          getTempKab();
        });
      });
    };

    // untuk mendapatkan kabupaten dari get id area
    const getTempKab = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getRegencies/" +
          JSON.parse(localStorage.getItem("idProvinsi")!)
      ).then((response) => {
        items.kabupatenOptions = response.data;

        let indexKab = items.kabupatenOptions.findIndex(
          (x) => x.name === editArea.address.city
        );

        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getRegencies/" +
            JSON.parse(localStorage.getItem("idProvinsi")!)
        ).then((response) => {
          localStorage.setItem(
            "idKab",
            JSON.stringify(response.data[indexKab].id)
          );

          getTempKec();
        });
      });
    };

    // untuk mendapatkan kecamatan dari get id area
    const getTempKec = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getDistricts/" +
          JSON.parse(localStorage.getItem("idKab")!)
      ).then((response) => {
        items.kecamatanOptions = response.data;
      });
    };

    const getProvinsi = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        items.provinsiOptions = response.data;
      });
    };

    const getKabupaten = () => {
      var indexProv = items.provinsiOptions.findIndex(
        (x) => x.name === editArea.address.province
      );

      if (indexProv != null) {
        var provId = items.provinsiOptions[indexProv].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getRegencies/" + provId
        ).then((response) => {
          items.kabupatenOptions = response.data;
        });
      }
    };

    const getKecamatan = () => {
      var indexKab = items.kabupatenOptions.findIndex(
        (x) => x.name === editArea.address.city
      );
      if (indexKab != null) {
        var kabupatenId = items.kabupatenOptions[indexKab].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getDistricts/" + kabupatenId
        ).then((response) => {
          items.kecamatanOptions = response.data;
        });
      }
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_BANK);
      setCurrentPageBreadcrumbs("Area", ["Data"]);
      getIdArea();
      getProvinsi();
    });

    return {
      submitButton,
      saveChanges1,
      editArea,
      editAreaValidator,
      items,
      getKabupaten,
      getKecamatan,
      getIdArea,
      getTempProv,
      getTempKab,
      getTempKec,
      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
